import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const NewsArchive = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  
  return (
    <section className="NewsArchive" style={{background: "#e8eff7"}} id={sect.header}>
      <div className="Container">
        <h2>{sect.header}</h2>
        <div className="news___articles">
          {slice.items.map((item,index) => (
            <div className="news___item" key={id+index}>
              <PrismicLink href={item.article_link?.url}>
                {item.article_title}
              </PrismicLink>
              <p >{item.article_source}</p>
              <p className="news___date">{item.article_date}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyNewsArchive on PrismicPageDataBodyNewsArchive {
    id
    primary {
      header
    }
    items {
      article_title
      article_source
      article_date(formatString: "MMMM YYYY")
      article_link{
        url
      }
    }
  }
`
