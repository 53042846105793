import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

export const BottomMenu = ({ bottomMenu,activeDocMeta }) => {
  const lang = activeDocMeta.lang.slice(0,2);

  return(
    <footer>
      <div className="logo-wrap">
        <PrismicLink href={`/${lang}`} >
          <GatsbyImage
            alt={bottomMenu.logo?.alt || "Connected North Logo"}
            image={bottomMenu.logo?.gatsbyImageData}
            className="logo"
          />
        </PrismicLink>
      </div>
      <div className="footer-wrap">
        <div className="col">
          <span>{bottomMenu.programs_header_label}</span>
          {bottomMenu.programs_links.map((item1,index) => (
            <div className="drop_item" key={index} >
              <PrismicLink 
                href={item1.link?.url} 
                className="footer-links" 
              >
                {item1.link_label} 
              </PrismicLink>
            </div>
          ))}
        </div>
        <div className="col">
          <span>{bottomMenu.teacher_header_label}</span>
          {bottomMenu.teacher_links.map((item1,index) => (
            <div className="drop_item" key={index} >
              <PrismicLink 
                href={item1.link?.url} 
                className="footer-links" 
              >
                {item1.link_label} 
              </PrismicLink>
            </div>
          ))}
        </div>
        <div className="col">
          <span>{bottomMenu.support_header_label}</span>
          {bottomMenu.support_links.map((item1,index) => (
            <div className="drop_item" key={index} >
              <PrismicLink 
                href={item1.link?.url} 
                className="footer-links"  
              >
                {item1.link_label} 
              </PrismicLink>
            </div>
          ))}
        </div>
        <div className="col">
          <div className="row">
            <span>{bottomMenu.founded_label}</span>
            <GatsbyImage
              alt={bottomMenu.founded_image?.alt || ""}
              image={bottomMenu.founded_image.gatsbyImageData}
              className="logo"
            />
          </div>
        </div>
        <div className="col">
          <div className="row">
            <span>{bottomMenu.lead_label}</span>
            <GatsbyImage
              alt=""
              image={bottomMenu.lead_image.gatsbyImageData}
              className="logo"
            />
          </div>
        </div>
      </div>
      <div className="logo-wrap">
        <p><b>{bottomMenu.copyright}</b></p>
      </div>
    </footer>
  )
}
export const query = graphql`
  fragment BottomMenuFragment on PrismicBottomMenu {
    _previewable
    type
    lang
    data {
      logo{
        gatsbyImageData(
          width: 288
          imgixParams: {q: 100}
        )
        alt
      }
      teacher_header_label
      teacher_links{
        link_label
        link{
          url
        }
      }
      programs_header_label
      programs_links{
        link_label
        link{
          url
        }
      }
      support_header_label
      support_links{
        link_label
        link{
          url
        }
      }
      lead_label
      lead_image{
        gatsbyImageData(width: 187)
        alt
      }
      founded_label
      founded_image{
        gatsbyImageData(width: 111)
        alt
      }
      copyright
    }
  }
`
