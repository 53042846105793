import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { customComponents } from '../utils/customComponents'

export const TextSection = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  return (
    <section className="TextSection" 
      style={{backgroundColor: sect.background_color , background: "url(" + sect.background_image?.url + ")"}}
      >
      <div className="Container">
        <PrismicRichText 
          field={sect.copy?.richText} 
          className="textsection___copy"
          components={customComponents}
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyTextSection on PrismicPageDataBodyTextSection {
    id
    primary {
      background_colour
      background_image {
        url
      }
      copy {
        richText
      }
    }
  }
`
