import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import AnchorLink from 'react-anchor-link-smooth-scroll' 
import { Layout } from '../components/Layout'
import { components } from '../slices'
import UpArrow from '../images/up-arrow.svg'

const UpdatedPageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicUpdatedPage
  const page = pageContent.data || {}

  const { lang, type, url } = pageContent
  const { seo_title, seo_description } = pageContent.data || {}

  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
    seo_title,
    seo_description,
  }
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}
  

  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="Updated_Page" >
        <SliceZone slices={page.body} components={components} context={{lang: lang, type: type}} />
        <div  className="page___header-link"><AnchorLink href="#header"><img src={UpArrow} alt="" className="up-arrow"/></AnchorLink></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query updatedPageQuery($id: String, $lang: String) {
    prismicUpdatedPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        seo_title
        seo_description
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...UpdatedPageDataBodyCardsIcons
          ...UpdatedPageDataBodyCardsImages
          ...UpdatedPageDataBodyCta
          ...UpdatedPageDataBodyCtaQuote
          ...UpdatedPageDataBodyHero
          ...UpdatedPageDataBodyHeroThermometer
          ...UpdatedPageDataBodyImageStats
          ...UpdatedPageDataBodyImageText
          ...UpdatedPageDataBodyList
          ...UpdatedPageDataBodyStickyNav
          ...UpdatedPageDataBodyTextButtons
          ...UpdatedPageDataBodyProgramPartners
          ...UpdatedPageDataBodyProgramSupporters
          ...UpdatedPageDataBodyPartners
          ...UpdatedPageDataBodyNewsletter
          ...UpdatedPageDataBodyStatsTitle
          ...UpdatedPageDataBodyTextIframeEmbed
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang }) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(UpdatedPageTemplate)
