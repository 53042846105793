import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const Principles = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  return (
    <section className="Principles" id={sect?.anchor_id}>
      <div className="Container">
        <h2>{sect.header}</h2>
        <div className="Principles___flex">
          {slice.items.map((item,index) => (
            <div className="Principles___item" key={id+index}>
              <GatsbyImage
                image={item.item_image?.gatsbyImageData}
                alt={item.item_image?.alt || ""}
              />
              <h4>{item.item_header}</h4>
              <p>{item.item_copy}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPrinciples on PrismicHomepageDataBodyPrinciples {
    primary {
      header
      anchor_id
    }
    id
    items {
      item_image{
        gatsbyImageData(width: 130)        
        alt
      }
      item_header
      item_copy
    }
  }
`
