import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicLink, PrismicRichText } from '@prismicio/react'

export const About = ({ slice }) => {
  const sect = slice.primary || {}
  return (
    <section className="About" id={sect?.anchor_id}>
      <div className="Container">
        <div className="flex-wrap">
          <div className="About___video">
            <iframe src={sect?.embedded_video_link?.embed_url}/>
          </div>
          <div className="About___content">
            <h2><span>{sect.subheader}</span>{sect.header}</h2>
            <p>{sect.description}</p>
            <PrismicLink href={sect?.link?.url || "#" } className="primary-btn"> {sect?.link_label}
            </PrismicLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAbout on PrismicHomepageDataBodyAbout {
    id
    primary {
      anchor_id
      embedded_video_link{
        embed_url
      }
      header
      subheader
      description
      link_label
      link{
        url
      }
    }
  }
`
