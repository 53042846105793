exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-thank-you-fr-js": () => import("./../../../src/pages/thankYou-fr.js" /* webpackChunkName: "component---src-pages-thank-you-fr-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thankYou.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-school-js": () => import("./../../../src/templates/school.js" /* webpackChunkName: "component---src-templates-school-js" */),
  "component---src-templates-updated-page-js": () => import("./../../../src/templates/updated_page.js" /* webpackChunkName: "component---src-templates-updated-page-js" */),
  "component---src-templates-wellbeing-subpage-js": () => import("./../../../src/templates/wellbeing_subpage.js" /* webpackChunkName: "component---src-templates-wellbeing-subpage-js" */)
}

