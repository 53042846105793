import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import Slider from "react-slick";

export const Programs = ({ slice }) => {
  function PrevArrow(props) {
    const { className, style} = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none"}}
      />
    );
  }
  function NextArrow(props) {
    const { className, onClick} = props;
    return (
      <div
        className="nextArrow"
        onClick={onClick}
      > > </div>
    );
  }
  const settings = {
    dots: false,

    speed: 500,
    adaptiveHeight:false,
    variableWidth:false,
    initialSlide: 0,
    centerMode: false,
    centerPadding: '20%',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    className:"Slider",
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 4,
          initialSlide: 0
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          initialSlide: 0
        }
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow:2,
          initialSlide: 0  
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          initialSlide: 0   
        }
      }
    ]
  };

  const sect = slice.primary || {}
  const id = slice.id || {}
  
  return (
    <section className="Programs" id={sect?.anchor_id}>
      <div className="Container">
        <div className="Programs___content">
          <h2>{sect.header}</h2>
          <p>{sect.description}</p>
        </div>
        <div className="Programs___carousel">
          <Slider {...settings}>
            {slice.items.map((item,index) => (
              <div className="card" key={id+index}>
                <div className="card___img-wrap">
                  <PrismicLink href={item.link?.url || "#"} >
                    <GatsbyImage 
                      image={item.program_image?.gatsbyImageData}
                      alt={item.program_image?.alt || ""}
                      layout="constrained"
                    />
                  </PrismicLink>  
                </div>
                <h5>{item.program_header}</h5>
                <p>{item.program_description}</p>
                <PrismicLink href={item.link?.url || "#"} >
                  {item.link_label}
                </PrismicLink>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPrograms on PrismicHomepageDataBodyPrograms {
    primary {
      anchor_id
      header
      description
    }
    id
    items {
      program_image{
        gatsbyImageData(
          height: 163
        )
        alt
      }
      program_header
      program_description
      link_label
      link{
        url
      }
    }
  }
`
