import * as React from 'react'
import { graphql } from 'gatsby'
import { StickyNav as Nav } from '../components/StickyNav'

export const StickyNav = ({ slice }) => {
  const links = slice?.items?.map(link => ({ navigation_label: link.anchor_label, navigation_id: link.anchor_id}));
  return (
    <section className="StickyNav">
      <Nav links={links || []}/>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyStickyNav on PrismicUpdatedPageDataBodyStickyNav {
    id
    items {
      anchor_label
      anchor_id
    }
  }
`