import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const FuturepathwaysHero = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}

  return (
    <section className="FuturepathwaysHero" >
      <div className="wrap" style={{background: "url(" + sect.background_image?.url + ")"}}> 
        <div className="Container">
          <div className="hero___frame">
            <GatsbyImage
              image={sect.main_image?.gatsbyImageData}
              alt={sect.main_image?.alt || ""}
              className="hero___main-image"
            />
          </div>
          <div className="hero___flex">
            {slice.items.map((item,index) => (
              <div className="hero___sponsor" key={id+index}>
                <GatsbyImage
                  image={item.sponsors?.gatsbyImageData}
                  alt={item.sponsors?.alt || ""}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="Container">
        <div className="hero___below-copy grey-line">
          <PrismicRichText field={sect.below_copy?.richText}/>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyFuturepathwaysHero on PrismicPageDataBodyFuturepathwaysHero {
    id
    primary {
      main_image{
        gatsbyImageData(imgixParams: {q: 100}, width: 500)
        url
        alt
      }
      background_image{
        url
      }
      below_copy{
        richText
      }
    }
    items{
      sponsors{
        gatsbyImageData(imgixParams: {fit: "contain"})
        alt
      }
    }
  }
`
