import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const ProgramSupporters = ({ slice, context }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  const { type } = context
  const isAltFormat = type === 'updated_page';
  
  return (
    <section className="Program-Supporters" id={slice.primary.anchor_id || undefined}>
      <div className="Container">
        <div className="Supporters___title">
          <h2>{sect.header_text}</h2>
        </div>
        {
          isAltFormat && 
          <div className="Supporters___names">
            <PrismicRichText field={sect.copy?.richText}/>
          </div>
        }
        <div className="Supporters___logos">
          {slice.items.map((item,index) => (
            <div  key={id+index}>
              <PrismicLink href={item.supporter_link?.url || "#"}>
                <GatsbyImage image={item.supporter_image?.gatsbyImageData} 
                  alt={item.supporter_image?.alt || ""} className='program-img-container'/>
              </PrismicLink>
            </div>
          ))}
        </div>
        {
          !isAltFormat && 
          <div className="Supporters___names">
            <PrismicRichText field={sect.copy?.richText}/>
          </div>
        }
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyProgramSupporters on PrismicPageDataBodyProgramSupporters {
    id
    primary{
      header_text: header
      copy{
        richText
      }
    }
    items{
      supporter_image{
        gatsbyImageData(width: 144)
        alt
      }
      supporter_link{
        url
      }
    }
  }
  fragment UpdatedPageDataBodyProgramSupporters on PrismicUpdatedPageDataBodyProgramSupporters {
    id
    primary{
      anchor_id
      header_text
      copy{
        richText
      }
    }
    items{
      supporter_image{
        gatsbyImageData(width: 144)
        alt
      }
      supporter_link{
        url
      }
    }
  }
`
