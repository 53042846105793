import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'
import { Thermometer } from '../components/Thermometer'

export const HeroThermometer = ({ slice, context }) => {
  const isEnglish = (context.lang === 'en-ca')

  return (
    <section className="WellbeingHero Hero">
      <div className="Container thermometerHero_container">
        <div className="header_and_text">
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
        </div>
        <div className="image_right">
          <Thermometer currentValue={slice.primary.current_value} maxValue={slice.primary.max_value} isEnglish={isEnglish}/>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyHeroThermometer on PrismicUpdatedPageDataBodyHeroThermometer {
    id
    primary {
      header {
        richText
      }
      text {
        richText
      }
      current_value
      max_value
    }
  }
`
