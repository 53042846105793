import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const Cta = ({ slice }) => {
  return (
    <section className="Cta" id={slice.primary.anchor_id || ''}>
      <div className="Slices_Container Container">
        <div className="Cta_Container">
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
          <div className="btn-container">
            {slice.items.map((btn, index) => {
              if(!isFilled.link(btn.button_link) && !isFilled.keyText(btn.button_label)) return;
              return (
                <PrismicLink href={btn.button_link.url} key={btn.button_label} className='primary-btn'>
                  {btn.button_label}
                </PrismicLink>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyCta on PrismicUpdatedPageDataBodyCta {
    id
    primary {
      anchor_id
      header {
        richText
      }
      text {
        richText
      }
    }
    items {
      button_link {
        url
      }
      button_label
    }
  }
`
