import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const ProgramPartners = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  
  return (
    <section className="Program-Partners" id={sect.anchor_id || undefined}>
      <div className="Container">
        <div className="flex-wrap">
          <div className="ProgramPartners___copy">
            <h2>{sect.header_text}</h2>
            <p>{sect.description}</p>

          </div>
          <div className="ProgramPartners___logos">
            {slice.items.map((item,index) => (
              <div key={id+index} className="ProgramPartners___items">
                <PrismicLink href={item.partner_link?.url || "#"}>
                  <GatsbyImage image={item.partner_image?.gatsbyImageData} 
                    alt={item.partner_image?.alt || ""} className='program-img-container'/>
                </PrismicLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyProgramPartners on PrismicPageDataBodyProgramPartners {
    id
    primary{
      header_text: header
      description
    }
    items{
      partner_image{
        gatsbyImageData
        alt
      }
      partner_link{
        url
      }
    }
  }
  fragment UpdatedPageDataBodyProgramPartners on PrismicUpdatedPageDataBodyProgramPartners {
    id
    primary{
      anchor_id
      header_text
      description
    }
    items{
      partner_image{
        gatsbyImageData
        alt
      }
      partner_link{
        url
      }
    }
  }
`
