import  React, { useRef, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicLink, SliceZone } from '@prismicio/react'
import { Layout } from '../components/Layout'
import { components } from '../slices'
import { StickyNav } from '../components/StickyNav'


const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const topMenu = data.prismicTopMenu || {}
  const bottomMenu = data.prismicBottomMenu || {}

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout topMenu={topMenu.data} bottomMenu={bottomMenu.data} activeDocMeta={activeDoc}>
      <div className="Homepage">
        <section className="Hero">
          <div className="Container">
            <div className="flex-wrap">
              <div className="Hero___copy">
                <h1>{homepage.data?.title}</h1>
                <p>{homepage.data?.subtitle}</p>
                <div className="btn-container">
                  <a href="https://anftswcf.donorsupport.co/-/XZYPLLCY" className="primary-btn">Donate</a>
                  <PrismicLink href={homepage.data.button_link_2?.url || "#"} className="secondary-btn">
                    {homepage.data?.button_label_2}
                  </PrismicLink>
                </div>
              </div>
              <div className="Hero___img-wrap">
                { homepage.data?.hero_image?.gatsbyImageData &&
                  <GatsbyImage image={homepage.data?.hero_image?.gatsbyImageData} 
                    alt={homepage.data?.hero_image?.alt || ""}/>
                }
              </div>
            </div>
          </div>
        </section>
        <section className="StickyNav">
          <StickyNav links={homepage.data?.navigation_links || []}/>
        </section>
        <SliceZone slices={homepage.data.body} components={components} context={{lang: lang}}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        title
        subtitle
        button_label_1
        button_link_1{
          url
        }
        button_label_2
        button_link_2{
          url
        }
        hero_image{
          gatsbyImageData(width: 900)
          alt
        }
        navigation_links{
          navigation_label
          navigation_id
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
        
          ...HomepageDataBodyPrograms
          ...HomepageDataBodyAbout
          ...HomepageDataBodySchools
          ...HomepageDataBodySupport
          ...HomepageDataBodyPartners
          ...HomepageDataBodyGetInvolved
          ...HomepageDataBodyPrinciples
          ...HomepageDataBodyNewsSection
          ...HomepageDataBodyMap

        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicBottomMenu(lang: { eq: $lang }) {
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(HomepageTemplate)
