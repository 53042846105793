import  React, { useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import MapMarker from "../../images/map-marker.png"
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  KmlLayer,
  Marker
} from "@react-google-maps/api";

const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = "AIzaSyCJxdplNccL1mEK13hKt-l3Ody6lZgoWDI"; // PUT GMAP API KEY HERE
const defaultLocation = { lat: 60.219386, lng: -101.645961 };


export const Map = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  
  const [map, setMap] = useState(null);  
  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);
    }
  }, [map]);

  return (
    <section className="Map" id={sect.anchor_id}>
      <div className="Container">
        <h2>{sect.header}</h2>
      </div>
      
      <LoadScript googleMapsApiKey={key} libraries={lib} mapIds={idG}>
          <GoogleMap
            center={defaultLocation}
            zoom={3}
            options={{ mapId: "614f2b924c037cf7" }}
            mapContainerStyle={{ height: "600px"}}
            disableDefaultUI={true}
            fullscreenControl={false}
          >
            { /* Child components, such as markers, info windows, etc. */ }
            {sect.file_link?.url && 
              <KmlLayer
                url={sect.file_link?.url}
                options={{ preserveViewport: true }}
              />
            }
          </GoogleMap>
        </LoadScript>

    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMap on PrismicHomepageDataBodyMap {
    primary {
      header
      anchor_id
      file_link {
        url
      }
    }
    id
  }
`
