import React, { useEffect, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect()
  const offsetTop = ele.offsetTop
  const offsetBottom = offsetTop + height

  return {
    height,
    offsetTop,
    offsetBottom,
  }
}

const isMobile = () => window.innerWidth <= 610

export const StickyNav = ({ links }) => {
  const [filteredLinks, setFilteredLinks] = useState([])
  function anchored(id) {
    return id ? '#' + id : '#header'
  }
  function selectHeight(items) {
    const scrollTop = window.scrollY + (isMobile() ? 150 : 95)
    if (
      scrollTop <
      (document.getElementById(items[0].navigation_id).offsetTop || 100000)
    ) {
      return ''
    }
    for (var i = 1; i < items.length; i++) {
      var prevID = items[i - 1].navigation_id
      var curID = items[i].navigation_id
      var curHeight = curID ? document.getElementById(curID).offsetTop : 0
      if (scrollTop < curHeight) {
        return prevID
      }
    }
    if (items.length === 1) return items[0].navigation_id
    return curID
  }
  const [visibleSection, setVisibleSection] = useState('')

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const newLinks = links.filter(
        (link) =>
          document.getElementById(link.navigation_id) && link.navigation_label,
      )
      setFilteredLinks(newLinks)
    }
  }, [links])

  useEffect(() => {
    const handleScroll = () => {
      if (filteredLinks.length > 0) {
        setVisibleSection(selectHeight(filteredLinks))
      }
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [visibleSection, filteredLinks])

  const styles = {
    active: {
      backgroundColor: '#94E0F8',
    },
    hidden: {
      backgroundColor: 'transparent',
    },
  }

  return (
    <div className="flex-nav">
      {filteredLinks.map((item, index) => (
        <AnchorLink
          offset={isMobile ? '90' : '95'}
          href={anchored(item.navigation_id)}
          key={index}
          className="anchorLink"
          style={
            visibleSection == item.navigation_id ? styles.active : styles.hidden
          }
        >
          {item.navigation_label}
        </AnchorLink>
      ))}
    </div>
  )
}
