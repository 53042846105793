import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const Partners = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  const sectHeader = sect.header_text || ""

  function sectionHeader(){
    if(sectHeader){
      return(
        <div className="Partners___header">
          <h2>{sectHeader}</h2>
        </div>
      )
    }
  }
  return (
    <section className="Partners" id={sect?.anchor_id}>
      <div className="Container">
        {sectionHeader()}
        <div className="flex-wrap">
          <div className="Partners___copy">
            <h3>{sect.subheader}</h3>
            <p>{sect.description}</p>
            {sect.partners_link?.url &&
              <PrismicLink href={sect.partners_link?.url} className="primary-btn">
                {sect.partners_link_label}
              </PrismicLink>
            }
          </div>
          <div className="Partners___items">
            {slice.items.map((item,index) => (
              <div className="Partners___card" key={id+index}>
                <PrismicLink href={item.partner_link?.url || "#"}>
                  <GatsbyImage image={item.partner_image?.gatsbyImageData} 
                    alt={item.partner_image?.alt || ""}/>
                </PrismicLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPartners on PrismicHomepageDataBodyPartners {
    primary {
      anchor_id
      header_text: header
      subheader
      description
      partners_link_label
      partners_link{
        url
      }
    }
    id
    items {
      partner_image{
        gatsbyImageData
        alt
      }
      partner_link{
        url
      }
    }
  }
  fragment PageDataBodyPartners on PrismicPageDataBodyPartners {
    primary {
      anchor_id
      header_text: header
      subheader
      description
      partners_link_label
      partners_link{
        url
      }
    }
    id
    items {
      partner_image{
        gatsbyImageData
        alt
      }
      partner_link{
        url
      }
    }
  }
  fragment UpdatedPageDataBodyPartners on PrismicUpdatedPageDataBodyPartners {
    primary {
      anchor_id
      header_text
      subheader
      description
      partners_link_label
      partners_link{
        url
      }
    }
    id
    items {
      partner_image{
        gatsbyImageData
        alt
      }
      partner_link{
        url
      }
    }
  }
`
