import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const GetInvolved = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  return (
    <section className="GetInvolved" id={slice.primary?.anchor_id}>
      <div className="Container">
        <div className="GetInvolved___copy">
          <h2>{sect.header}</h2>
          <p>{sect.description}</p>
          <PrismicLink href={sect.involved_link?.url || "#"} className="primary-btn">
            {sect.involved_link_label}
          </PrismicLink>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyGetInvolved on PrismicHomepageDataBodyGetInvolved {
    primary {
      anchor_id
      header
      description
      involved_link_label
      involved_link{
        url
      }
    }
  }
  fragment PageDataBodyGetInvolved on PrismicPageDataBodyGetInvolved {
    primary {
      anchor_id
      header
      description
      involved_link_label
      involved_link{
        url
      }
    }
  }
`
