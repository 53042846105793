import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const TextButtons = ({ slice }) => {

  return (
    <section className="TextButtons" id={slice.primary.anchor_id || ""}>
      <div className="Slices_Container Container">
        <div className="Cards__text">
        <div className="mb-8">
          {isFilled.richText(slice.primary.header.richText) &&
            <PrismicRichText field={slice.primary.header.richText}/>
          }
          {isFilled.richText(slice.primary.text.richText) &&
            <PrismicRichText field={slice.primary.text.richText}/>
          }
          </div>
          <div className="btn-container">
            {slice.items.map((btn, index) => {
              if(!isFilled.link(btn.button_link) && !isFilled.keyText(btn.button_label)) return;
              return (
                <PrismicLink href={btn.button_link.url} key={btn.button_label} className={`${index > 0 ? 'secondary-btn' : 'primary-btn'}`}>
                  {btn.button_label}
                </PrismicLink>
              )
            })}
          </div>
          </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyTextButtons on PrismicUpdatedPageDataBodyTextButtons {
    id
    primary {
      anchor_id
      header {
        richText
      }
      text {
        richText
      }
    }
    items {
      button_link {
        url
      }
      button_label
    }
  }
`