import * as React from 'react'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const Contact = ({ slice,context }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  const thank_you = context.lang.slice(0,2) == "fr" ? "/thankYou-fr/" : "/thankYou/";

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const [state, setState] = React.useState({})
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/en/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <section className="Contact" >
      <div className="Container">
        <div className="flex-wrap">
          <div className="Contact___info">
            <h2>{sect.get_involved_header}</h2>
            <PrismicRichText field={sect.content?.richText}/>
          </div>
          <div className="Contact___form">
            <h2>{sect.contact_form_header}</h2>
            <form 
              name="contact-form"
              method="POST" 
              netlify-honeypot="bot-field"
              data-netlify="true" 
              onSubmit={handleSubmit}
              action={thank_you}
            >
            
              <input type="hidden" name="form-name" value="contact-form" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <label htmlFor="name"><h5>{sect.name_label}</h5></label>
              <input id="name" name="name" type="text" className="Contact___form-input" onChange={handleChange}/>
              <label htmlFor="phone"><h5>{sect.phone_number_label}</h5></label>
              <input id="phone" name="phone" type="text" className="Contact___form-input" onChange={handleChange}/>
              <label htmlFor="email"><h5>{sect.email_label}</h5></label>
              <input id="email" name="email" type="text" className="Contact___form-input" onChange={handleChange}/>
              <label htmlFor="message"><h5>{sect.message_label}</h5></label>
              <textarea id="message" name="message" rows="6" className="Contact___form-input" onChange={handleChange}/>
              <button type="submit" name="submit" className="primary-btn">{sect.submit_button_label}</button>
        
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyContact on PrismicPageDataBodyContact {
    primary {
      get_involved_header
      content{
        richText
      }
      contact_form_header
      name_label
      phone_number_label
      email_label
      message_label
      submit_button_label
    }
  }
`
