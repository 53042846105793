import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const List = ({ slice, context }) => {
  const lang = context.lang
  const isEnglish = lang === 'en-ca'
  const debounce = require('../utils/debounce').default

  // Check screen size - Expand if >= 610
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setExpanded(window.innerWidth >= 610)
    }, 500)

    debouncedHandleResize()

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  const [expanded, setExpanded] = React.useState(false)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const getListClassName = () => {
    switch (slice.primary.columns) {
      case 1:
        return 'list'
      case 2:
        return 'list two-columns-list'
      case 3:
        return 'list three-columns-list'
      case 4:
        return 'list four-columns-list'
    }
  }

  return (
    <section className="List" id={slice.primary.anchor_id || ''}>
      <div className="Container Slices_Container_left">
        <div className="list-heading">
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
        </div>
        {/* List */}
        {isFilled.richText(slice.primary.list.richText) && (
          <div className={getListClassName()}>
            <PrismicRichText
              field={
                expanded
                  ? slice.primary.list.richText
                  : slice.primary.list.richText.slice(0, 5)
              }
            />
            {slice.primary.list.richText.length > 5 && (
              <button className="read-more" onClick={toggleExpand}>
                {expanded
                  ? isEnglish
                    ? 'Read less'
                    : 'Lire moins'
                  : isEnglish
                  ? 'Read more'
                  : 'Lire plus'}
              </button>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyList on PrismicUpdatedPageDataBodyList {
    id
    primary {
      anchor_id
      header {
        richText
      }
      text {
        richText
      }
      list {
        richText
      }
      columns
    }
  }
`
