import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const NewsSection = ({ slice,context }) => {
  const {
    header,
    anchor_id,
    news_link_label,
    news_link 
  } = slice.primary || {}
  const id = slice.id || {}
  const lang = context.lang;

  function hasImage(image) {
    if (image.gatsbyImageData) return (
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image?.alt || ""}
        className="news___article-image"  
      />
    )
  }
  const news_data = useStaticQuery(graphql`
    query NewsSectionQuery{
      allPrismicPage(filter: {uid: {eq: "archive"}}) {
        nodes {
          data {
            body {
              ... on PrismicPageDataBodyNewsArchive {
                id
                items {
                  article_title
                  article_date(formatString: "MMMM YYYY")
                  article_image {
                    gatsbyImageData
                    alt
                  }
                  article_link {
                    url
                  }
                  article_source
                }
              }
            }
          }
          lang
        }
      }
    }
  `);
  const newsData = news_data.allPrismicPage.nodes.find(page => page.lang == lang) || [];

  return (
    <section className="NewsSection" style={{background: "#e8eff7"}} id={anchor_id}>
      <div className="Container">
        <div className="news___articles">
          <h2>{header}</h2>
          <div className="news___articles">
            { newsData.data.body[0].items.slice(0,10).map((item,index) => (
              <div className="news___item" key={id+index}>
                <div className="news___image">
                  { hasImage(item.article_image)}
                </div>
                <div className="news___copy">

                  <PrismicLink href={item.article_link?.url}>
                    {item.article_title}
                  </PrismicLink>
                  <p >{item.article_source}</p>
                  <p className="news___date">{item.article_date}</p>
                </div>
              </div>
            ))}
          </div>
          <PrismicLink
            href={news_link?.url}
            className="primary-btn">
            {news_link_label}
          </PrismicLink>
        </div>
        
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyNewsSection on PrismicHomepageDataBodyNewsSection {
    id
    primary {
      anchor_id
      header
      news_link{
        url
      }
      news_link_label
    }
  }
`
