// Debounce and resizing screen taken from: https://www.pluralsight.com/resources/blog/guides/re-render-react-component-on-window-resize
function debounce(func, delay) {
  let timeoutId

  return function debounced(...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      timeoutId = null
      func.apply(this, args)
    }, delay)
  }
}

export default debounce;
