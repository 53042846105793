import  React, { useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const Schools = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  
  return (
    <section className="Schools" id={sect.anchor_id}>
      <div className="Container">
        
        <div className="Schools___cards">
          <h2>{sect.header}</h2>
          <div className="flex-wrap">
              <div className="card___minor" >
                <h4>{sect.minor_stat_number_1}</h4>
                <p>{sect.minor_stat_description_1}</p>
              </div>
              <div className="card___minor">
                <h4>{sect.minor_stat_number_2}</h4>
                <p>{sect.minor_stat_description_2}</p>
              </div>
              <div className="card___minor" >
                <h4>{sect.minor_stat_number_3}</h4>
                <p>{sect.minor_stat_description_3}</p>
              </div>
              <div className="card___minor">
                <h4>{sect.minor_stat_number_4}</h4>
                <p>{sect.minor_stat_description_4}</p>
              </div>
          </div>
        </div>
        <div className="Schools___image">
          <div className="Schools___major-cards">
            <div className="card___major">
              <h4>{sect.major_stat_number_1}</h4>
              <p>{sect.major_stat_description_1}</p>
            </div>
            <div className="card___major">
              <h4>{sect.major_stat_number_2}</h4>
              <p>{sect.major_stat_description_2}</p>
            </div>
          </div>
          <GatsbyImage image={sect.schools_image?.gatsbyImageData} 
            alt={sect.schools_image?.alt || ""} className="card___image-wrap"/>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySchools on PrismicHomepageDataBodySchools {
    id
    primary {
      anchor_id
      header
      major_stat_number_1
      major_stat_description_1
      major_stat_number_2
      major_stat_description_2
      minor_stat_number_1
      minor_stat_description_1
      minor_stat_number_2
      minor_stat_description_2
      minor_stat_number_3
      minor_stat_description_3
      minor_stat_number_4
      minor_stat_description_4
      schools_image{
        gatsbyImageData(width: 1028)
        alt
      }
    }  
  }
`
