import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import AnchorLink from 'react-anchor-link-smooth-scroll' 

export const LearnMoreHero = ({ slice }) => {
  
  const {title, subtitle, background_image } = slice.primary || {}
  const id = slice.id || {}
  const Background = background_image?.url || ""
  
  return (
    <section className="LearnMoreHero" style={{background: "url(" + Background + ")"}}>

      <div className="Container">
        <div className="inner">
          <h1>{title || ""}</h1>
          <p>{subtitle || ""}</p>
          <div className="LearnMore___nav">
            {slice.items.map((item,index) => (
              <AnchorLink href={item.anchor_link_id || null} key={id + index} className="anchorLink" style={{color: item.link_color}}>
                <img src={item.icon?.url} alt="" className="achorLink___icon"/>
                <span>{item.label || ""}</span>
              </AnchorLink>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyLearnMoreHero on PrismicPageDataBodyLearnMoreHero {
    id
    primary{
      title
      subtitle
      background_image{
        url
      }
    }
    items{
      icon{
        url
      }
      label
      anchor_link_id
      link_color
    }
  }
`
