import React, { useState } from 'react'

export const Thermometer = ({ currentValue=0, maxValue=0, isEnglish }) => {
  const donationAmount = (currentValue / maxValue) * 100
  const [temperature, setTemperature] = useState(donationAmount)

  const formattedCurrentValue = currentValue.toLocaleString()
  const formattedMaxValue = maxValue.toLocaleString()

  return (
    <div className="thermometer_container">
      <div className="thermometer">
        <div className="goal">
          {isEnglish
            ? `Goal: $${formattedMaxValue}`
            : `Objectif: ${formattedMaxValue}$`}
        </div>
        <div
          className="temperature"
          style={{ height: `${temperature}%` }}
          data-value={
            isEnglish
              ? `Current: $${formattedCurrentValue}`
              : `Actuel: ${formattedCurrentValue}$`
          }
        ></div>
        <div className="intervals">
          <span className="interval"></span>
          <span className="interval"></span>
          <span className="interval"></span>
          <span className="interval"></span>
        </div>
      </div>
    </div>
  )
}
