import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const Publications = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}

  return (
    <section className="Publications" >
      <div className="Container">
        <div className="Publications___header">
          <h2>{sect.header}</h2>
        </div>
        <div className="Publications___grid">
          {slice.items.map((item,index) => (
            <PrismicLink href={item.publication_link?.url} key={id+index} className="Publications___grid-item">
              <GatsbyImage
                image={item.publication_image?.gatsbyImageData}
                alt={item.publication_image?.alt || ""}
                className="Publication___image"
              />
              <div className="Publication___copy">
                <p>{item.publication_title}</p>
              </div>
            </PrismicLink>
          ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyPublications on PrismicPageDataBodyPublications {
    id
    primary {
      header
    }
    items{
      publication_image{
        gatsbyImageData(width: 650)
        alt
      }
      publication_link {
        url 
      }
      publication_title
    }
  }
`
