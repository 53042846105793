import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import Framework from "../../images/Framework.svg" 
import Framework2 from "../../images/Framework2.svg"
import { Thermometer } from '../../components/Thermometer'

// Styling sheet is in _wellbeing-hero.scss

export const AnotherFramework = ({ slice,context }) => {
  const sect = slice.primary || {}
  const source = context.source || ''
  const lang = context.lang
  
  const isAlternate = source == 'tsista' ? true : false;
 
  return (
    <section className="anotherFramework" style={{background: sect.background}}>
      <div className="Container">
        <div className="anotherFramework___wrap">
          <PrismicRichText field={sect.anotherframework_title?.richText}/>
          <p className="subtitle">{sect.anotherframework_subtitle}</p>
          <div className='framework-image'>
            {isAlternate ?
              <PrismicLink className="" href={sect?.anotherframework_link?.url}>
                <img 
                  src={ Framework }
                  alt={ lang === 'en-ca' ? "Artwork of a Well-being Framework by Alexander Angnaluak" : "Oeuvre d'un cadre de bien-être par Alexander Angnaluak" }
                />
              </PrismicLink>
              :
              <PrismicLink className="" href={sect?.anotherframework_link?.url}>
                <img 
                  src={ Framework2 }
                  alt={ lang === 'en-ca' ? "Artwork of a Well-being Framework by Tsista Kennedy" : "Oeuvre d'un cadre de bien-être par Tsista Kennedy" }
                />
              </PrismicLink>
            }
          </div>
          {slice.items.map((item, index) => (
              <p key={index}>
               {item.anotherframework_caption}
              </p>
            ))}        
          </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyAnotherFramework on PrismicPageDataBodyAnotherFramework {
    id
    primary {
     anotherframework_title {
      richText
     }
     anotherframework_subtitle
     anotherframework_link {
      url
     }
     anotherframework_background
    }
    items {
     anotherframework_caption
    }
  }
`