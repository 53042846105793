import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'


export const ImageText = ({ slice }) => {
  return (
    <section className="ImageText" id={slice.primary.anchor_id || ''}>
      <div className="Slices_Container Container ImageText__flex">
        <div
          className={slice.primary.image_side ? 'image_left' : 'image_right'}
        >
          {isFilled.image(slice.primary.image) && (
            <div className="image_container">
              <GatsbyImage
                image={slice.primary.image.gatsbyImageData}
                alt={slice.primary.image.alt || ''}
              />
            </div>
          )}
        </div>
        <div className="header_and_text">
          {isFilled.richText(slice.primary.header.richText) && (
            <PrismicRichText field={slice.primary.header.richText} />
          )}
          {isFilled.richText(slice.primary.text.richText) && (
            <PrismicRichText field={slice.primary.text.richText} />
          )}
          <div className="btn-container">
            {slice.items.map((btn) => {
              if (
                !isFilled.link(btn.button_link) ||
                !isFilled.keyText(btn.button_label)
              )
                return
              return (
                <PrismicLink href={btn.button_link.url} key={`${btn.button_label} ${btn.button_link.url}`} className="primary-btn">
                  {btn.button_label}
                </PrismicLink>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyImageText on PrismicUpdatedPageDataBodyImageText {
    id
    primary {
      anchor_id
      image_side
      header {
        richText
        text
      }
      text {
        richText
      }
      image {
        gatsbyImageData
        url
        alt
      }
    }
    items {
      button_link {
        url
      }
      button_label
    }
  }
`
