import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const PageNav = ({ slice, context }) => {
  const sect = slice.primary || {}
  const source = context.source
  const lang = context.lang
  const isAlternate = source == 'tsista' ? true : false;

  return (
    <section className="wellbeing-cta" style={{background: sect.background, paddingTop: 20, paddingBottom:80}}>
      <div className="Container">
        <StaticQuery
          query={graphql`
            query MyQuery{
              allPrismicWellbeingSubpage{
                nodes{
                  url
                  id
                  lang
                  data{
                    display_title
                    hero_image{
                      gatsbyImageData(
                        width: 200, imgixParams: {fit: "none"} 
                      )
                    }
                    alt_image{
                      gatsbyImageData(
                        width: 200, imgixParams: {fit: "none"} 
                      )
                    }
                  }
                }
              }
            }
          `}
          render={data => 
            <div className="cta___wrap">
              <div className="cta___nav">
                {data.allPrismicWellbeingSubpage.nodes.filter(pages=>pages.lang == lang).map((page,index) => (
                  <>
                    {isAlternate ?
                      <PrismicLink className="cta___link" href={page?.url+`?framework=${source}` || "#"} key={page.id}>
                        <GatsbyImage 
                          image={page.data?.alt_image?.gatsbyImageData} 
                          alt="" 
                          className="cta___link-image cta___link-image-alt"
                        />
                        <p>{page.data?.display_title}</p>
                      </PrismicLink>
                      :
                      <PrismicLink className="cta___link" href={page.url || "#"} key={page.id}>
                        <GatsbyImage 
                          image={page.data?.hero_image?.gatsbyImageData} 
                          alt="" 
                          className="cta___link-image"
                        />
                        <p>{page.data?.display_title}</p>
                      </PrismicLink>
                    }
                  </>
                ))}
              </div>
            </div>
            
          }
        />
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyPageNav on PrismicPageDataBodyPageNav {
    id
    primary{
      background
    }
  }
  
`
