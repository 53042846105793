import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const Support = ({ slice }) => {
  const sect = slice.primary || {}
  const id = slice.id || {}
  return (
    <section className="Support" id={sect?.anchor_id}>
      <div className="Container">
        <div className="Support___copy">
          <h2>{sect.header}</h2>
          <p>{sect.description}</p>
        </div>
        <div className="Support___items">
          <div className="flex-wrap">
            {slice.items.map((item,index) => (
              <div className="Support___card" key={id+index}>
                <PrismicLink href={item.item_link?.url || "#"}>
                  <GatsbyImage image={item.item_image?.gatsbyImageData} 
                    alt={item.item_image?.alt || ""} className="Support___card-img"/>
                </PrismicLink>  
                <div>
                  <p>{item.item_name}</p>
                  <p>{item.item_price}</p>
                  <PrismicLink href={item.item_link?.url || "#"}>
                    {item.item_link_label}
                  </PrismicLink>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="Support___btn">
          <PrismicLink href={sect.shop_link?.url || "#"} className="primary-btn lg-btn">
            {sect.shop_link_label}
          </PrismicLink>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodySupport on PrismicHomepageDataBodySupport {
    primary {
      anchor_id
      header
      description
      shop_link_label
      shop_link{
        url
      }
    }
    id
    items {
      item_image{
        gatsbyImageData(
          height: 312
        )
        alt
      }
      item_name
      item_price
      item_link_label
      item_link{
        url
      }
    }
  }
`
