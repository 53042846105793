import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'

export const Hero = ({ slice }) => {

  return (
    <section className="WellbeingHero Hero">
      <div className="Container Hero__flex">
          <div
            className={slice.primary.image_side ? 'image_right' : 'image_left'}
          >
            {isFilled.image(slice.primary.image) && (
                <GatsbyImage
                  image={slice.primary.image.gatsbyImageData}
                  alt={slice.primary.image.alt || ''}
                />
            )}
          </div>
          <div className="header_and_text">
            {isFilled.richText(slice.primary.header.richText) && (
              <PrismicRichText field={slice.primary.header.richText} />
            )}
            {isFilled.richText(slice.primary.text.richText) && (
              <PrismicRichText field={slice.primary.text.richText} />
            )}
          </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyHero on PrismicUpdatedPageDataBodyHero {
    id
    primary {
      image_side
      header {
        richText
      }
      text {
        richText
      }
      image {
        gatsbyImageData
        url
        alt
      }
    }
  }
`
