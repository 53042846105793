import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import Framework from "../../images/Framework.svg" 
import Framework2 from "../../images/Framework2.svg"

export const WellbeingHero = ({ slice,context }) => {
  const sect = slice.primary || {}
  const source = context.source || ''
  
  const isAlternate = source == 'tsista' ? true : false;
 
  return (
    <section className="WellbeingHero">
      <div className="Container">
        <div className="WellbeingHero___wrap">
          <PrismicRichText field={sect.wellbeing_title?.richText}/>
          <p className="subtitle">{sect.subtitle}</p>
          
          <div className={`wellbeing-image ${isAlternate ? 'image-alt' : 'image-default'}`}>
            {isAlternate ?
              <>
                <img 
                  src={ Framework2 }
                  useMap="#image-map"
                />
                <map name="image-map">
                  <area target="" alt="Relationships With Peers and Students" title="Relationships With Peers and Students" href={slice.items[0]?.subpage_link?.url+`?framework=${source}`} coords="175,224,47"  shape="circle"/>

                  <area target="" alt="Culture, Language, and Identity" title="Culture, Language, and Identity" href={slice.items[1]?.subpage_link?.url+`?framework=${source}`} coords="243,145,271,152,281,181,301,223,259,228,230,221,225,189,215,155" shape="poly"/>

                  <area target="" alt="Hope for the Future" title="Hope for the Future" href={slice.items[2]?.subpage_link?.url+`?framework=${source}`} coords="212,66,247,69,260,102,257,131,231,131,213,103,205,87" shape="poly"/>
                  <area target="" alt="Hope for the Future" title="Hope for the Future" href={slice.items[2]?.subpage_link?.url+`?framework=${source}`} coords="26,161,53,165,56,200,33,205,17,190,13,182"  shape="poly"/>
                  <area target="" alt="Hope for the Future" title="Hope for the Future" href={slice.items[2]?.subpage_link?.url+`?framework=${source}`} coords="38,13,74,26,76,48,58,59,36,61,26,36" shape="poly"/>

                  <area target="" alt="Food and Nutrition" title="Food and Nutrition" href={slice.items[3]?.subpage_link?.url+`?framework=${source}`} coords="63,195,64,158,76,121,112,132,124,154,111,194,99,224,63,209" shape="poly"/>

                  <area target="" alt="Arts and Recreation" title="Arts and Recreation" href={slice.items[4]?.subpage_link?.url+`?framework=${source}`} coords="13,62,70,84,57,120,26,138,3,121,4,85" shape="poly"/>
                  <area target="" alt="Arts and Recreation" title="Arts and Recreation" href={slice.items[4]?.subpage_link?.url+`?framework=${source}`} coords="262,9,313,1,325,50,298,66,260,65" shape="poly"/>
                  <area target="" alt="Arts and Recreation" title="Arts and Recreation" href={slice.items[4]?.subpage_link?.url+`?framework=${source}`} coords="277,89,324,103,326,135,300,157,265,147,256,130,266,104" shape="poly"/>

                  <area target="" alt="Relationships with Staff and Authority" title="Relationships with Staff and Authority" href={slice.items[5]?.subpage_link?.url+`?framework=${source}`} coords="134,119,187,94,220,126,209,158,160,158,127,140" shape="poly"/>
                  
                  <area target="" alt="Parents and Family Involvements" title="Parents and Family Involvements" href={slice.items[6]?.subpage_link?.url+`?framework=${source}`} coords="328,460,3,458,4,205,61,207,107,239,179,294,262,225,329,233" shape="poly"/>
                  
                </map>
              </> :
              <>
                <img 
                  src={ Framework }
                  useMap="#image-map"
                />
                <map name="image-map">
                  <area target="" alt="" title="Wellbeing" href={slice.items[0]?.subpage_link?.url} coords="79,9,193,58" shape="rect"/>
                  <area target="" alt="" title="" href={slice.items[1]?.subpage_link?.url} coords="26,60,247,116" shape="rect"/>
                  <area target="" alt="" title="" href={slice.items[2]?.subpage_link?.url} coords="39,122,132,172" shape="rect"/>
                  <area target="" alt="" title="" href={slice.items[3]?.subpage_link?.url} coords="148,118,213,172" shape="rect"/>
                  <area target="" alt="" title="" href={slice.items[4]?.subpage_link?.url} coords="10,174,244,233" shape="rect"/>
                  <area target="" alt="" title="" href={slice.items[5]?.subpage_link?.url} coords="5,247,91,390" shape="rect"/>
                  <area target="" alt="" title="" href={slice.items[6]?.subpage_link?.url} coords="152,260,254,384" shape="rect"/>
                </map>
              </>
            }
            {slice.items.map((item,index) => (
              <PrismicLink href={item.subpage_link?.url+isAlternate ? `?framework=${source}` : ``} key={index} className="link-label">
                {item.link_label}
              </PrismicLink>
            ))}
          </div>
          <p className="image-tag">{sect.image_tag}</p>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyWellbeingHero on PrismicPageDataBodyWellbeingHero {
    id
    primary {
      image_tag
      subtitle
      wellbeing_title{
        richText
      }
    }
    items {
      subpage_link {
        url
      }
      link_label 
    }
  }
`
