import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { isFilled } from '@prismicio/helpers'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const decodeHtmlEntities = (str) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
};

const stripHtmlTags = (str) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.body.textContent || "";
};

export const Newsletter = ({ slice }) => {
  const [email, setEmail] = React.useState('')
  const [result, setResult] = React.useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const result = await addToMailchimp(email)
    result.msg = stripHtmlTags(decodeHtmlEntities(result.msg))
    setResult(result)
  }
  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <section className="Newsletter" id={slice.primary.anchor_id || ''}>
      <div className="Slices_Container Container">
        <div className="Newsletter_Container">
          {/* Text */}
          <div className="Newsletter_text">
            {isFilled.richText(slice.primary.header.richText) && (
              <PrismicRichText field={slice.primary.header.richText} />
            )}
            {isFilled.richText(slice.primary.text.richText) && (
              <PrismicRichText field={slice.primary.text.richText} />
            )}
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" className="visually-hidden">
                {'Email Address:'}
              </label>
              <div className="Newsletter_input">
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="Newsletter_input_box"
                  placeholder={slice.primary.email_placeholder}
                  onChange={handleChange}
                />
                <input
                  type="submit"
                  value={slice.primary.button_label || 'Submit'}
                  className="primary-btn"
                />
              </div>
            </form>
            {result?.result == 'success' ? (
              <p>{result?.msg}</p>
            ) : result?.result == 'error' ? (
              <p>{result?.msg}</p>
            ) : null}
          </div>
        </div>

        <div className="Newsletter_image_container">
          <GatsbyImage
            image={slice.primary.image.gatsbyImageData}
            alt={slice.primary.image.alt || ''}
          />
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment UpdatedPageDataBodyNewsletter on PrismicUpdatedPageDataBodyNewsletter {
    id
    primary {
      header {
        richText
        text
      }
      text {
        richText
      }
      button_label
      email_placeholder
      image {
        gatsbyImageData
        url
        alt
      }
    }
  }
`
