import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { FaChevronDown, FaChevronRight, FaArrowLeft, FaUser } from 'react-icons/fa'
import { LanguageSwitcher } from './LanguageSwitcher'

export const TopMenu = ({ topMenu, activeDocMeta }) => {

  const lang = activeDocMeta.lang.slice(0,2);
  
  const isBrowser = () => typeof window !== "undefined"
  const [isMobile, setIsMobile] = React.useState(isBrowser() && window.innerWidth < 960);
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  const desktopMenu = (
    <ul className="nav-menu">
      <li className="item">
        <div className="dropdown">
          <span tabIndex="1">{topMenu.teacher_dropdown_label} <FaChevronDown/></span>
          <div className="dropdown-content">
            <div className="dropdown-wrap">
              <div className="col">
                <h4>{topMenu.teacher_title}</h4>
                <p>{topMenu.teacher_description}</p>
              </div>
              <div className="row">
                {topMenu.teacher_links.map((item,index) => (
                  <div className="drop_item" key={index} >
                    <p><b>{item.link_label}</b></p> 
                    <PrismicLink 
                      href={item.link?.url || "/"} 
                      className="nav-links" 
                      
                      onClick={click ? handleClick : null}
                    >
                      <div className="box lg">
                        <GatsbyImage
                          alt=""
                          image={item.link_image?.gatsbyImageData}
                        />
                      </div>
                    </PrismicLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="item">
        <div className="dropdown">
          <span>{topMenu.programs_dropdown_label} <FaChevronDown/></span>
          <div className="dropdown-content">
            <div className="dropdown-wrap">
              <div className="col">
                <h4>{topMenu.programs_title}</h4>
                <p>{topMenu.programs_description}</p>
              </div>
              <div className="row">
                {topMenu.programs_links.map((item,index) => (
                  <div className="drop_item" key={index} >
                    <p><b>{item.link_label}</b></p> 
                    <PrismicLink 
                      href={item.link?.url || "/"} 
                      className="nav-links" 
                      onClick={click ? handleClick : null}
                    >
                      <div className="box sm">
                        <GatsbyImage
                          alt=""
                          image={item.link_image?.gatsbyImageData}
                        />
                      </div>
                    </PrismicLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="item">
        <div className="dropdown">
          <span>{topMenu.support_dropdown_label} <FaChevronDown/></span>
          <div className="dropdown-content">
            <div className="dropdown-wrap">
              <div className="col">
                <h4>{topMenu.support_title}</h4>
                <p>{topMenu.support_description}</p>
              </div>
              <div className="row">
                {topMenu.support_links.map((item,index) => (
                  <div className="drop_item" key={index} >
                    <p><b>{item.link_label}</b></p> 
                    <PrismicLink 
                      href={item.link?.url} 
                      className="nav-links" 
                     
                      onClick={click ? handleClick : null}
                    >
                      <div className="box lg">
                        <GatsbyImage
                          alt=""
                          image={item.link_image?.gatsbyImageData}
                        />
                      </div>
                    </PrismicLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </li>
      <li className="item">
        <PrismicLink 
          href={topMenu.contact_link?.url} 
          className="nav-links" 
          onClick={click ? handleClick : null}
        >
          {topMenu.contact_label}
        </PrismicLink>
      </li>
      <li className="item">
        <PrismicLink 
          href={topMenu.login_link?.url} 
          className="nav-links login-link" 
          onClick={click ? handleClick : null}
        >
          <FaUser className="login-icon"/>
          {topMenu.login_label}
        </PrismicLink>
      </li>
      <li className="item">
        <a className="nav-links primary-btn lg-btn" href="#XWYGNNDW" style={{display:"none"}}></a>
      </li>
    </ul>
  )
  const mobileMenu = (
    <div className="mobile-container">
      <div className={click ? "nav-icon close" : "nav-icon"} onClick={handleClick} tabIndex="0">
        <span className="ham bar-1"/>
        <span className="ham bar-2"/>
        <span className="ham bar-3"/>
      </div>
      <ul className={click ? "mobile-menu menu-open" : "mobile-menu"}>
        <li className="mobile-item">
          <div className = "mobile-dropdown">
            <input type="checkbox" id="dropdown-1"/>
            <label htmlFor="dropdown-1"><h5>{topMenu.teacher_title} <FaChevronRight/></h5><FaArrowLeft className="return"/></label>
           
            <div className="mobile-dropdown-content">
              <h4>{topMenu.teacher_title}</h4>
              <p>{topMenu.teacher_description}</p>
              {topMenu.teacher_links.map((item,index) => (
                <PrismicLink 
                  href={item.link?.url || "/"} 
                  className="mobile-nav-links" 
                  onClick={click ? handleClick : null}
                  key={index}
                >
                  <h5>{item.link_label}</h5>
                </PrismicLink>
              ))}
              <a className="nav-links primary-btn lg-btn" href="#XWYGNNDW"></a>
            </div>
          </div>
        </li>
        <li className="mobile-item">

          <div className = "mobile-dropdown">
            <input type="checkbox" id="dropdown-2"/>
            <label htmlFor="dropdown-2"><h5>{topMenu.programs_title} <FaChevronRight/></h5><FaArrowLeft className="return"/></label>
           
            <div className="mobile-dropdown-content">
              <h4>{topMenu.programs_title}</h4>
              <p>{topMenu.programs_description}</p>
              {topMenu.programs_links.map((item,index) => (
                <PrismicLink 
                  href={item.link?.url || "/"} 
                  className="mobile-nav-links" 
                  onClick={click ? handleClick : null}
                  key={index}
                >
                  <h5>{item.link_label}</h5>
                </PrismicLink>
              ))}
              <PrismicLink 
                href={topMenu.donate_link?.url} 
                className="nav-links primary-btn lg-btn" 
                onClick={click ? handleClick : null}
              >
                {topMenu.donate_label}
              </PrismicLink>
            </div>
          </div>
        </li>
        <li className="mobile-item">
          <div className = "mobile-dropdown">
            <input type="checkbox" id="dropdown-3"/>
            <label htmlFor="dropdown-3"><h5>{topMenu.support_title} <FaChevronRight/></h5><FaArrowLeft className="return"/></label>
           
            <div className="mobile-dropdown-content">
              <h4>{topMenu.support_title}</h4>
              <p>{topMenu.support_description}</p>
              {topMenu.support_links.map((item,index) => (
                <PrismicLink 
                  href={item.link?.url || "/"} 
                  className="mobile-nav-links" 
                  onClick={click ? handleClick : null}
                  key={index}
                >
                  <h5>{item.link_label}</h5>
                </PrismicLink>
              ))}
              <PrismicLink 
                href={topMenu.donate_link?.url} 
                className="nav-links primary-btn lg-btn" 
                onClick={click ? handleClick : null}
              >
                {topMenu.donate_label}
              </PrismicLink>
            </div>
          </div>
        </li>
        <li className="mobile-item">
          <PrismicLink 
            href={topMenu.contact_link?.url} 
            className="nav-links" 
            onClick={click ? handleClick : null}
          >
            <h5>{topMenu.contact_label}</h5>
          </PrismicLink>
        </li>
        <li className="mobile-item">
          <PrismicLink 
            href={topMenu.login_link?.url} 
            className="nav-links" 
            onClick={click ? handleClick : null}
          >
            <h5>{topMenu.login_label}</h5>
          </PrismicLink>
        </li>
        <li className="mobile-item">
          <PrismicLink 
            href={topMenu.donate_link?.url} 
            className="nav-links primary-btn lg-btn" 
            onClick={click ? handleClick : null}
          >
            {topMenu.donate_label}
          </PrismicLink>
        </li>
      </ul>
    </div>
  )
  return (
    <header id="header">
      <div className="banner">
        <LanguageSwitcher activeDocMeta={activeDocMeta} />
      </div>
      <div className={click ? "main-container" : "" } onClick={()=>Close()}/>
      <nav className="navbar">
        
          <PrismicLink href={`/${lang}`} className="nav-logo">
            <GatsbyImage
              alt="Connected North Logo"
              image={topMenu.logo?.gatsbyImageData}
              className="nav-logo___full"
            />  
          </PrismicLink>

          {isMobile ? mobileMenu : desktopMenu }
        
          
      </nav>
    </header>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      logo{
        gatsbyImageData(
          width: 288
          imgixParams: {q: 100}
        )
        alt
      }
      teacher_dropdown_label
      teacher_title
      teacher_description
      teacher_links{
        link_label
        link{
          url
        }
        link_image{
          gatsbyImageData
        }
      }
      programs_dropdown_label
      programs_title
      programs_description
      programs_links{
        link_label
        link{
          url
        }
        link_image{
          gatsbyImageData
          alt
        }
      }
      support_dropdown_label
      support_title
      support_description
      support_links{
        link_label
        link{
          url
        }
        link_image{
          gatsbyImageData
          alt
        }
      }
      contact_label
      contact_link{
        url
      }
      login_label
      login_link{
        url
      }
    }
  }
`
