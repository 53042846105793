import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

export const TextAndImage = ({ slice }) => {
  const {
    anchor_id, 
    header, 
    header_color, 
    header_icon, 
    copy,
    image_link, 
    image
  } = slice.primary || {}
  const id = slice.id || {}

  const imageSection = (
    <GatsbyImage
      className="TextAndImage___image"
      image={image?.gatsbyImageData}
      alt={image.alt || ""}
    />
  )
  
  return (
    <section className="TextAndImage" id={anchor_id}>
      <div className="Container">
        <div className="TextAndImage___wrap" >
          <div className="TextAndImage___copy">
            <div className="TextAndImage___header">
              <img src={header_icon?.url} alt=""/>
              <h2 style={{color: header_color}}>{ header|| "" }</h2>
            </div>
            <PrismicRichText field={copy.richText}/>
          </div>
          {image_link?.url ? 
            <PrismicLink href={image_link?.url}> {imageSection}</PrismicLink> :
            imageSection
          }
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyTextAndImage on PrismicPageDataBodyTextAndImage {
    id
    primary{
      anchor_id
      header
      header_color
      header_icon{
        url
      }
      copy{
        richText
      }
      image_link {
        url
      }
      image{
        gatsbyImageData(width: 400)
        alt
      }
    }
  }
`
